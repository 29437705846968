





























































































import { Component, Vue } from 'vue-property-decorator'
import { validateIdCard, getUserAuthenticationType } from '@/api'
import {
  appFunc_sendOpenImage,
  appFunc_RegisterFinishCb,
  appFunc_callExit,
  appFunc_senToken
} from '@/utils/bridge'
import { Toast } from 'vant'
import { CommonModule } from '@/store/modules/common'
@Component({
  name: 'Certification'
})
export default class Certification extends Vue {
  form = {
    name: '',
    idCard: '',
    cardImage: ''
  }
  // 是否实名认证，0-否，1-是
  verification = 0

  loading = false

  async created() {
    //获取登陆token
    await this.getToken()

    //获取用户信息
    this.getUserAuthenticationType()

    // 获取当前语言
  }

  mounted() {
    // 挂载完成后，判断浏览器是否支持popstate--物理返回键
    if (window.history && (window.history.pushState as any)) {
      history.pushState(null, '', document.URL)
      window.addEventListener('popstate', this.handelback, false)
    }
  }

  //页面销毁时，取消监听。否则其他vue路由页面也会被监听--物理返回键
  destroyed() {
    window.removeEventListener('popstate', this.handelback, false)
  }

  async getToken() {
    const res = await appFunc_senToken()
    console.log(res, '/////////////')
    CommonModule.setUserId(res.id)
    CommonModule.setToken(res.tokenForJs)
    CommonModule.setUserPhone(res.mobile)
    CommonModule.setUserName(res.name)
  }

  // 获取用户信息
  async getUserAuthenticationType() {
    const { code, data } = await getUserAuthenticationType()
    if (code == 1001) {
      this.verification = data.verification
    }
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }

  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language
  }

  handelFocusname() {
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['fill_in_name', 'CLK', { type: '实名认证' }]
    })
  }

  handelFocusCard() {
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['Fill_in_the_ID_number', 'CLK', { type: '实名认证' }]
    })
  }

  handelUpload() {
    appFunc_sendOpenImage()
    appFunc_RegisterFinishCb(this.handelFinish)
  }

  handelFinish(data: any) {
    if (/(iPhone|iOS)/i.test(navigator.userAgent)) {
      console.log(data, '////////')
      const pictureList = data
      console.log(pictureList, '返回图片路径')
      this.form.cardImage = pictureList[0]
      const { aplus_queue }: any = window
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['Scan_ID_card_information', 'CLK', { type: '实名认证' }]
      })
    } else if (/(Android)/i.test(navigator.userAgent)) {
      console.log(data, '////////')
      const pictureList = JSON.parse(data)
      console.log(pictureList, '返回图片路径')
      this.form.cardImage = pictureList[0]
      const { aplus_queue }: any = window
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['Scan_ID_card_information', 'CLK', { type: '实名认证' }]
      })
    }

    console.log(this.form.cardImage, '////////')
  }

  async handelSubmit() {
    const card18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    if (this.form.name == '' && this.form.cardImage == '') {
      return Toast(this.$t('请先填写姓名'))
    }
    if (this.form.idCard == '' && this.form.cardImage == '') {
      return Toast(this.$t('请先填写身份证号码'))
    }
    if (!card18.test(this.form.idCard) && this.form.cardImage == '') {
      return Toast(this.$t('请输入正确的身份证号码'))
    }
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: [
        'Submit_real_name_authentication',
        'CLK',
        { type: '实名认证' }
      ]
    })
    this.loading = true
    const { code, message } = await validateIdCard({
      name: this.form.name,
      idCard: this.form.idCard,
      cardImage: this.form.cardImage
    })
    if (code == 1001) {
      this.loading = false
      this.getUserAuthenticationType()
      Toast(this.$t('身份认证提交成功'))
    } else {
      this.loading = false
      Toast(message)
    }
  }

  handelback() {
    appFunc_callExit()
  }
}
